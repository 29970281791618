import React, { useState, useEffect } from 'react';
import YouthGameComponent from './YouthGameComponent'
import * as CommonHelper from "../../../../helpers/commonHelper";
import queryString from "query-string";
import {isEmptyObject} from "../../../../helpers/objectHelper";
import {getYouthGameCodeList, getYouthGameScheduleList, getYouthGameTeamList} from "../../../../service/youth/Match.service";
import {youthGameType} from "../../../../helpers/commonHelper";

const YouthGameContainer = props => {
    const { history, location, match } = props;
    const qs = queryString.parse(location.search);
    const { season, groupCode, info, school, group, team } = qs;
    const [scheduleTypeList, setScheduleTypeList] = useState(null);
    const [scheduleType, setScheduleType] = useState('league');
    const [seasonList, setSeasonList] = useState(null);
    const [seasonKind, setSeasonKind] = useState('1');
    const [seasonCode, setSeasonCode] = useState(null);
    const [seasonBanner, setSeasonBanner] = useState(null);
    const [gameList, setGameList] = useState(null);
    const [game, setGame] = useState(null);
    const [schoolList, setSchoolList] = useState(null);
    const [groupList, setGroupList] = useState(null);
    const [teamList, setTeamList] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", resizeCallback);
        resizeCallback();

        return () => {
            window.removeEventListener("resize", resizeCallback);
        }
    }, [])

    const resizeCallback = () => {
        setIsMobile(window.innerWidth <= 768);
    }

    // tab
    const [curTab, setCurTab] = useState('schedule');
    const tabList = [
        {
            key: 'schedule',
            value: '일정 결과'
        },
        {
            key: 'teamRank',
            value: '예선 순위'
        },
        /*{
            key: 'playerRank',
            value: '개인 순위'
        },*/
        {
            key: 'playerInfo',
            value: '팀 정보'
        },
        {
            key: 'gameInfo',
            value: '대회 정보'
        },
        {
            key: 'record',
            value: '역대 전적'
        },
    ];

    const scheduleInfoMap = {
        '1' : { // 클럽대회
            typeList: [
                {key: 'tournamentBracket', value: '본선 대진표'},
                // {key: 'final', value: '본선리그'},
                {key: 'league', value: '예선리그'},
            ]
        },
        '2' : { // 주말리그
            typeList: [
                {key: 'tournamentBracket', value: '본선 대진표'},
                {key: 'league', value: '예선리그'},
                // {key: 'final', value: '본선리그'},
            ]
        },
        '3' : { // 스쿨리그
            typeList: [
                {key: 'league', value: '지역리그'},
                {key: 'tournamentBracket', value: '본선리그'},
            ]
        },
        '4' : { // 농구최강전
            typeList: [
                {key: 'league', value: '예선리그'},
                {key: 'tournamentBracket', value: '본선리그'},
            ]
        },
    };

    const changeParams = async (type, key, value) => {
        if (type === 'changeTab') {
            setCurTab(key);
            qs['type'] = key;
            history.push(history.location.pathname + "?" + queryString.stringify(qs));
        } else if (type === 'changeScheduleType') {
            setScheduleType(key);
            qs['info'] = key;
            qs['type'] = 'schedule';
            history.push(history.location.pathname + "?" + queryString.stringify(qs));

            if(key === 'league') { // 예선리그
                await getGameListByScheduleType(key)
            } else if (key === 'final')  { // 본선리그(토너먼트)
                await getGameListByScheduleType(key)
            } else if (key === 'tournamentBracket') { // 토너먼트 대진표
                await getGameListByScheduleType(key)
            }
        } else if (type === 'changeSeason') {
            setSeasonCode(key);
            qs['season'] = key;
            qs['groupCode'] = "";
            history.push(history.location.pathname + "?" + queryString.stringify(qs));
        } else if (type === 'changeSchool') { // 학년 (gameList)
            qs['school'] = key;
            history.push(history.location.pathname + "?" + queryString.stringify(qs));
            await getGameListByScheduleType(scheduleType, {groupCode: key !== 'all' ? key : ''});
        } else if (type === 'changeGroup') { // 조 그룹 (gameList)
            qs['group'] = key;
            history.push(history.location.pathname + "?" + queryString.stringify(qs));
            await getGameListByScheduleType(scheduleType, {gameGroup: key !== 'all' ? key : ''});
        } else if (type === 'changeTeam') { // 구단/팀명 (gameList)
            qs['team'] = key;
            history.push(history.location.pathname + "?" + queryString.stringify(qs));
            await getGameListByScheduleType(scheduleType, {teamCode: key !== 'all' ? key : ''});
        } else if (type === 'changeGame') { // 경기 기록 상세
            setGame(key);
            history.push(`${history.location.pathname}/${key}`)
        } else if (type === 'changeTeamGroup') { // 팀 정보
            qs['groupCode'] = key;
            history.push(history.location.pathname + "?" + queryString.stringify(qs));
        }
    }

    const getSchoolList = list => {
        let result = [];
        result.push({key: 'all', value: '부서'});
        if(list && list.length > 0) {
            list.map(item => {
                let obj = {};
                obj.key = item.code;
                obj.value = item.groupName;
                obj.groupCode = item.groupCode;
                result.push(obj);
            })
        }
        return result;
    }

    const getGroupList = (list) => {
        let result = [];
        result.push({key: 'all', value: `${seasonKind == 1 ? '조' : '권역'}`});
        if(list && list.length > 0) {
            list.map(item => {
                if (seasonKind == 1) {
                    let obj = {};
                    obj.key = item.code;
                    obj.value = item.title;
                    result.push(obj);
                } else if(seasonKind == 2 && item.code < 4) {
                    let obj = {};
                    obj.key = item.code;
                    obj.value = item.title;
                    result.push(obj);
                }
            })
        }
        return result;
    }

    const getTeamList = list => {
        let result = [];
        result.push({key: 'all', value: '구단'});
        if(list && list.length > 0) {
            let filtered = list.filter(data => data.isDeleted !== 1);
            filtered.map(item => {
                let obj = {};
                obj.key = item.code;
                obj.value = item.title;
                result.push(obj);
            })
        }
        return result;
    }

    const getGameListByScheduleType = async (type, extraParams) => {
        if(!type || !seasonCode) return;

        const searchParams = {
            seasonKind: seasonKind,
            seasonCode: seasonCode,
            gameKind: type === 'league' ? '1' : '2',
            groupCode: school !== 'all' ? school : '', // 학년
            gameGroup: group !== 'all' ? group : '', // 조
            teamCode: team !== 'all' ? team : '', // 구단
            isBracket: type === 'tournamentBracket'
        };

        if (extraParams) {
            for (let value of Object.entries(extraParams)) {
                const extraKey = value[0];
                const extraValue = value[1];
                searchParams[extraKey] = extraValue;
            }
        }

        const _scheduleList = await getYouthGameScheduleList(searchParams);
        if(_scheduleList && Array.isArray(_scheduleList)){
            let result = [];
            let obj = {};
            const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토', '일'];
            const GAME_LEVEL_KIND = ['', '조별예선', '결승', '준결승', '4강', '', '6강', '', '8강', '3.4위전', '10강'];

            if(type === 'tournamentBracket') { // 토너먼트 대진표 (학년별)
                _scheduleList.map((value, index) => {
                    if (index === 0 || value.groupCode !== obj.groupCode) {
                        if (index !== 0) {
                            result.push(obj);
                        }
                        obj = {};
                        obj.groupCode = value.groupCode;
                        obj.groupName = value.school;
                        obj.groupTitle = `${value.seasonName} 플레이오프(${value.school})`;
                        obj.gameDateForDisp = value.gdate;
                        obj.weekDay = WEEKDAY[value.gameWeek];
                        obj.place = value.place;
                        obj.gameLevelTitle = GAME_LEVEL_KIND[value.gameLevel];
                        obj.videoSq = value.videoSq;
                        obj.photoSq = value.photoSq;
                        obj.list = [];
                    }

                    value.gameLevelTitle = GAME_LEVEL_KIND[value.gameLevel];
                    value.weekDay = WEEKDAY[value.gameWeek];
                    obj.list.push(value);

                    if (index + 1 === _scheduleList.length) {
                        result.push(obj);
                    }
                })
            } else {
                _scheduleList.map((value, index) => {
                    if (index === 0 || value.gameDate !== obj.gameDate) {
                        if (index !== 0) {
                            result.push(obj);
                        }
                        obj = {};
                        obj.gameDate = value.gameDate;
                        obj.gameDateForDisp = value.gdate;
                        obj.weekDay = WEEKDAY[value.gameWeek];
                        obj.list = [];
                    }
                    value.gameLevelTitle = value.gameLevel ? GAME_LEVEL_KIND[value.gameLevel] : '조별예선';
                    obj.list.push(value);

                    if (index + 1 === _scheduleList.length) {
                        result.push(obj);
                    }
                })
            }

            setGameList(result)
        }
    }

    const getDataList = async () => {
        let gameType = match.params.gameType;
        let curSeasonKind = youthGameType[gameType ? gameType : 'club'];
        setSeasonKind(curSeasonKind);

        let _info = scheduleInfoMap[curSeasonKind];
        if(!isEmptyObject(_info)){
            let filterList = [..._info.typeList];
            if(gameType == 'weekend' && seasonCode <= 9){
                filterList = filterList.filter(item => item.key != 'tournamentBracket');
            }
            setScheduleTypeList(filterList)
            // let type = gameType == 'weekend' && (seasonCode > 9 || seasonCode == null) ?  _info.typeList[0].key : _info.typeList[_info.typeList.length - 1].key; //주말리그(16) 예선부터 노출 -> 240205부터는 본선부터
            let type = filterList[filterList.length - 1].key;
            if(info){
                setScheduleType(info);
                await getGameListByScheduleType(info);
            }else{
                setScheduleType(type);
                await getGameListByScheduleType(type);
            }
            // await getGameListByScheduleType(type);
        }
        const _seasonSelectList = await CommonHelper.getYouthSeasonSelectList({seasonKind: curSeasonKind}, false, false);
        setSeasonList(_seasonSelectList);
        if(_seasonSelectList && Array.isArray(_seasonSelectList)){
            if(!season) {
                setSeasonCode(_seasonSelectList[0].key);
                setSeasonBanner(_seasonSelectList[0].imgPath);
            } else {
                let curSeasonInfo = _seasonSelectList.find(item => item.key == season);
                if(curSeasonInfo) {
                    setSeasonCode(curSeasonInfo.key);
                    setSeasonBanner(curSeasonInfo.imgPath);
                } else {
                    setSeasonCode(_seasonSelectList[0].key);
                    setSeasonBanner(_seasonSelectList[0].imgPath);
                }
            }
        }
    }

    useEffect(_ => {
        const _queryString = queryString.parse(history.location.search);
        if (!isEmptyObject(_queryString)) {
            let tab = _queryString.type;
            setCurTab(tab);
        }
    }, [])

    useEffect(_ => {
        if(seasonList && seasonCode) {
            let curSeason = seasonList.find(item => item.key == seasonCode);
            if(curSeason) {
                setSeasonBanner(curSeason.imgPath);
            }
        }
    }, [seasonCode, seasonKind])

    useEffect(_ => {
        getDataList();
        return function cleanup() {
            window.scrollTo(0, 0);
        }
    }, [match])

    useEffect(_ => {

        const getDefaultInfo = async _ => {
            if(!seasonKind ||!seasonCode) return
            let _info = scheduleInfoMap[seasonKind];
            if(!isEmptyObject(_info)){
                if(seasonCode == 5 || seasonCode == 7) {
                    let list = _info.typeList.filter(item => item.key === 'league');
                    _info.typeList = list;
                }
                if(seasonKind == 2 && seasonCode <= 9){
                    let list = _info.typeList.filter(item => item.key != 'tournamentBracket');
                    _info.typeList = list;
                }
                setScheduleTypeList(_info.typeList)
                // let type = _info.typeList[_info.typeList.length - 1].key;
                // setScheduleType(type);
                await getGameListByScheduleType(scheduleType);
            }
            setSchoolList(getSchoolList(await getYouthGameTeamList({seasonKind: seasonKind, seasonCode: seasonCode})));
            setGroupList(getGroupList(await getYouthGameCodeList({seasonKind: seasonKind, seasonCode: seasonCode, codeKind: 5})));
            setTeamList(getTeamList(await getYouthGameCodeList({seasonKind: seasonKind, seasonCode: seasonCode})));
        }
        getDefaultInfo();
    }, [seasonKind, seasonCode]);

    return (
        <YouthGameComponent
            {...props}
            changeParams={changeParams}
            scheduleTypeList={scheduleTypeList}
            scheduleType={scheduleType}
            seasonList={seasonList}
            seasonKind={seasonKind}
            seasonCode={seasonCode}
            seasonBanner={seasonBanner}
            gameList={gameList}
            game={game}
            schoolList={schoolList}
            school={school}
            groupList={groupList}
            group={group}
            groupCode={groupCode}
            teamList={teamList}
            team={team}
            tabList={tabList}
            curTab={curTab}
            isMobile={isMobile}
        />
    )
}

export default YouthGameContainer;