import React, { useState } from 'react';
import EmptyGameComponent from "../../../../commons/table/EmptyGameComponent";
import AlertComponent from "../../../../commons/popup/AlertComponent";

const YouthTournamentComponent = props => {
    const { history, changeParams, gameList, isMobile, seasonKind } = props;
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    //const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토', '일'];
    const changeGame = e => changeParams('changeGame', e);

    const alertPopupClose = e => {
        e.preventDefault();
        setIsAlertPopupDisplay(false);
        setAlertMessage('');
    }

    const pageRender = e => {
        e.preventDefault();
        const id = e.currentTarget.dataset.id;
        const type = e.currentTarget.dataset.type;
        if(!id || id === undefined) {
            setAlertMessage('데이터가 없습니다.');
            setIsAlertPopupDisplay(true);
            return;
        }

        history.push(`/youth/contents/${type}/${id}`);
    }

    return (
        <>
            <AlertComponent
                clickCloseButton={alertPopupClose}
                isDisplay={isAlertPopupDisplay}
                content={alertMessage}
            />
            <div className={`youth-match-con-wrap`} style={{display: 'none'}}>
                <div className={`youth-match-con-add type02`}>
                    <div className={`round final`}>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <ul className={`more`}>
                                <li>기록</li>
                                <li>영상</li>
                                <li>사진</li>
                            </ul>
                        </div>
                    </div>
                    <div className={`round semifinal team01 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <ul className={`more`}>
                                <li>기록</li>
                                <li>영상</li>
                                <li>사진</li>
                            </ul>
                        </div>
                    </div>
                    <div className={`round semifinal team02 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <ul className={`more`}>
                                <li>기록</li>
                                <li>영상</li>
                                <li>사진</li>
                            </ul>
                        </div>
                    </div>
                    <div className={`round quarter team01 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <div>
                                <p>2019.08.18(일)</p>
                                <span>09:34 -10:30</span>
                            </div>
                        </div>
                    </div>
                    <div className={`round quarter team02 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <div>
                                <p>2019.08.18(일)</p>
                                <span>09:34 -10:30</span>
                            </div>
                        </div>
                    </div>
                    <div className={`round quarter team03 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <div>
                                <p>2019.08.18(일)</p>
                                <span>09:34 -10:30</span>
                            </div>
                        </div>
                    </div>
                    <div className={`round quarter team04 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <div>
                                <p>2019.08.18(일)</p>
                                <span>09:34 -10:30</span>
                            </div>
                        </div>
                    </div>
                    <div className={`round match team01 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <ul className={`more`}>
                                <li>기록</li>
                                <li>영상</li>
                                <li>사진</li>
                            </ul>
                        </div>
                    </div>
                    <div className={`round match team02 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round match team03 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <ul className={`more`}>
                                <li>기록</li>
                                <li>영상</li>
                                <li>사진</li>
                            </ul>
                        </div>
                    </div>
                    <div className={`round match team04 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round match team05 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <ul className={`more`}>
                                <li>기록</li>
                                <li>영상</li>
                                <li>사진</li>
                            </ul>
                        </div>
                    </div>
                    <div className={`round match team06 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round match team07 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <ul className={`more`}>
                                <li>기록</li>
                                <li>영상</li>
                                <li>사진</li>
                            </ul>
                        </div>
                    </div>
                    <div className={`round match team08 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`youth-match-con-wrap`} style={{display: 'none'}}>
                <div className={`youth-match-con-add type01`}>
                    <div className={`round final`}>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <button>경기기록</button>
                        </div>
                    </div>
                    <div className={`round semifinal team01 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <button>경기기록</button>
                        </div>
                    </div>
                    <div className={`round semifinal team02 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <button>경기기록</button>
                        </div>
                    </div>
                    <div className={`round quarter team01 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <button>경기기록</button>
                        </div>
                    </div>
                    <div className={`round quarter team02 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round quarter team03 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round quarter team04 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <button>경기기록</button>
                        </div>
                    </div>
                    <div className={`round quarter-nomi team01 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round quarter-nomi team02 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round quarter-nomi team03 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round quarter-nomi team04 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round match team01 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <button>경기기록</button>
                        </div>
                    </div>
                    <div className={`round match team02 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round match team03 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <button>경기기록</button>
                        </div>
                    </div>
                    <div className={`round match team04 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round match team05 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <button>경기기록</button>
                        </div>
                    </div>
                    <div className={`round match team06 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round match team07 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                <li className={`win`}>38</li>
                                <li className={``}>24</li>
                            </ul>
                            <button>경기기록</button>
                        </div>
                    </div>
                    <div className={`round match team08 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`youth-match-con-wrap`} style={{display: 'none'}}>
                <div className={`youth-match-con-add type03`}>
                    <div className={`round final`}>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className='emblem-kbl-wh'></i>
                                {/* <i className={`emblem-kcc`} /> */}
                            </div>
                            <h6>
                                {/* 현대모비스 */}
                                결승
                            </h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                {/* <li className={`win`}>38</li>
                                <li className={``}>24</li> */}
                                <li><p>15:30 / 신관</p></li>
                            </ul>
                            {/* <button>경기기록</button> */}
                        </div>
                    </div>
                    <div className={`round semifinal team01`}>
                    {/* <div className={`round semifinal team01 win`}> */}
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kbl-gr`} />
                                {/* <i className={`emblem-kcc`} /> */}
                            </div>
                            <h6>
                                {/* 현대모비스 */}
                                4강 1경기
                            </h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                {/* <li className={`win`}>38</li>
                                <li className={``}>24</li> */}
                                <li className={``}><p>12:50 / 신관</p></li>
                            </ul>
                            {/* <button>경기기록</button> */}
                        </div>
                    </div>
                    <div className={`round semifinal team02`}>
                    {/* <div className={`round semifinal team02 lose`}> */}
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kbl-gr`} />
                                {/* <i className={`emblem-kcc`} /> */}
                            </div>
                            <h6>
                                {/* 현대모비스 */}
                                4강 2경기
                            </h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                {/* <li className={`win`}>38</li>
                                <li className={``}>24</li> */}
                                <li className={``}><p>12:50 / 구관</p></li>
                            </ul>
                            {/* <button>경기기록</button> */}
                        </div>
                    </div>
                    <div className={`round quarter team01`}>
                    {/* <div className={`round quarter team01 lose`}> */}
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kbl-gr`} />
                                {/* <i className={`emblem-kcc`} /> */}
                            </div>
                            <h6>6강 1경기</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                {/* <li className={`win`}>38</li>
                                <li className={``}>24</li> */}
                                <li className={``}><p>09:30 / 신관</p></li>
                            </ul>
                            {/* <button>경기기록</button> */}
                        </div>
                    </div>
                    <div className={`round quarter team02`}>
                    {/* <div className={`round quarter team02 win`}> */}
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kbl-gr`} />
                                {/* <i className={`emblem-kcc`} /> */}
                            </div>
                            <h6>6강 2경기</h6>
                        </div>
                        <div className={`info`}>
                            <ul className={`score`}>
                                {/* <li className={`win`}>38</li>
                                <li className={``}>24</li> */}
                                <li className={``}><p>09:30 / 구관</p></li>
                            </ul>
                            {/* <button>경기기록</button> */}
                        </div>
                    </div>
                    <div className={`round match team01 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round match team02 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round match team03 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round match team04 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round match team05 lose`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                    <div className={`round match team06 win`}>
                        <div className={`line`}>
                            <span />
                        </div>
                        <div className={`team`}>
                            <div className={`img`}>
                                <i className={`emblem-kcc`} />
                            </div>
                            <h6>현대모비스</h6>
                        </div>
                    </div>
                </div>
            </div>
            {
                gameList && gameList.length > 0 ?
                    gameList.map((item, index) =>
                        <div key={`game_${index}`} className={`con-box`}>
                            <div className={`youth-match-tit`}>
                                <div className={`tit`}>
                                    <h4>{item.groupTitle}</h4>
                                    {/*<p>{item.gameDateForDisp}({item.weekDay}) / {item.place}</p>*/}
                                    <p>{item.gameDateForDisp}({item.weekDay})</p>
                                </div>
                                {
                                    seasonKind == 1 ?
                                        <div className={`btn`}>
                                            <button className={`light-pb`} onClick={pageRender} data-type={'video'} data-id={item.videoSq} >경기 영상 보기</button>
                                            <button className={`pb`} onClick={pageRender} data-type={'photo'} data-id={item.photoSq}>경기 사진 보기</button>
                                        </div>
                                    : null
                                }
                            </div>
                            {
                                seasonKind == 1 ?
                                    item.list && item.list.length > 0 ?
                                        <div className={`youth-match-con`}>
                                            {
                                                item.list.map((i, idx) =>
                                                    i.gameLevel === '2' ?
                                                        <>
                                                            <div className={`round final`} key={`game_${i.gmkey}`}>
                                                                {
                                                                    i.gameStatus > 2 ?
                                                                        <div className={`team`}>
                                                                            <div className={`img`}>
                                                                                <img src={`/imgs/youth/emblem/em_KBL${i.homeScore > i.awayScore ? (i.homeCode == 'X1' || i.homeCode == 'X2' ? 'XX' : i.homeCode) : (i.awayCode == 'X1' || i.awayCode == 'X2' ? 'XX' : i.awayCode)}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                                {/*<i className={`emblem-db`} />*/}
                                                                            </div>
                                                                            <h6>{i.homeScore > i.awayScore ? i.tnameH : i.tnameA}</h6>
                                                                        </div>
                                                                        :
                                                                        <div className={`team`}>
                                                                            <div className={`img`}>
                                                                                <img src={`/imgs/youth/emblem/em_KBLXX.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                            </div>
                                                                            <h6>우승</h6>
                                                                        </div>
                                                                }
                                                                <div className={`info`}>
                                                                    <ul className={`score`}>
                                                                        {
                                                                            i.gameStatus > 0 ?
                                                                                <>
                                                                                    <li className={`${i.gameStatus > 2 && i.homeScore > i.awayScore ? 'win' : ''}`}>{i.homeScore}</li>
                                                                                    <li className={`${i.gameStatus > 2 && i.homeScore < i.awayScore ? 'win' : ''}`}>{i.awayScore}</li>
                                                                                </>
                                                                                :
                                                                                <li>{i.gameTime?.substring(0,5)}</li>
                                                                        }
                                                                    </ul>
                                                                    <ul className={`score`}>
                                                                        {
                                                                            i.gameStatus > 0 ?
                                                                                null
                                                                                :
                                                                                <li>{i.place}</li>
                                                                        }
                                                                    </ul>
                                                                    <ul className={`more`} style={{display: i.gameStatus > 0 ? '' : 'none'}}>
                                                                        <li onClick={()=>{changeGame(i.gmkey)}}>기록</li>
                                                                        <li onClick={pageRender} data-type={'video'} data-id={i.videoSq}>영상</li>
                                                                        <li onClick={pageRender} data-type={'photo'} data-id={i.photoSq}>사진</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className={`round semifinal team01 ${i.gameStatus > 2 && i.homeScore > i.awayScore ? 'win' : 'lose'}`}>
                                                                <div className={`line`} />
                                                            </div>
                                                            <div className={`round semifinal team02 ${i.gameStatus > 2 && i.homeScore < i.awayScore ? 'win' : 'lose'}`}>
                                                                <div className={`line`} />
                                                            </div>
                                                        </>
                                                        : i.ord === 1 ?
                                                            <>
                                                                <div className={`round semifinal team01 ${i.gameStatus > 2 && i.homeScore > i.awayScore ? 'win' : ''}`}>
                                                                    {/*<div className={`line`} />*/}
                                                                    {
                                                                        i.gameStatus > 2 ?
                                                                            <div className={`team`}>
                                                                                <div className={`img`}>
                                                                                    <img src={`/imgs/youth/emblem/em_KBL${i.homeScore > i.awayScore ? (i.homeCode == 'X1' || i.homeCode == 'X2' ? 'XX' : i.homeCode) : (i.awayCode == 'X1' || i.awayCode == 'X2' ? 'XX' : i.awayCode)}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                                    {/*<i className={`emblem-hd`} />*/}
                                                                                </div>
                                                                                <h6>
                                                                                    {i.homeScore > i.awayScore ? i.tnameH : i.tnameA}
                                                                                </h6>
                                                                            </div>
                                                                            :
                                                                            <div className={`team`}>
                                                                                <div className={`img`}>
                                                                                    <img src={`/imgs/youth/emblem/em_KBLXX.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                                </div>
                                                                                <h6>결승</h6>
                                                                            </div>
                                                                    }
                                                                    <div className={`info`}>
                                                                        <ul className={`score`}>
                                                                            {
                                                                                i.gameStatus > 0 ?
                                                                                    <>
                                                                                        <li className={`${i.gameStatus > 2 && i.homeScore > i.awayScore ? 'win' : ''}`}>{i.homeScore}</li>
                                                                                        <li className={`${i.gameStatus > 2 && i.homeScore < i.awayScore ? 'win' : ''}`}>{i.awayScore}</li>
                                                                                    </>
                                                                                    :
                                                                                    <li>{i.gameTime?.substring(0,5)}</li>
                                                                            }
                                                                        </ul>

                                                                        <ul className={`score`}>
                                                                            {
                                                                                i.gameStatus > 0 ?
                                                                                    null
                                                                                    :
                                                                                    <li>{i.place}</li>
                                                                            }
                                                                        </ul>

                                                                        <ul className={`more`} style={{display: i.gameStatus > 0 ? '' : 'none'}}>
                                                                            <li onClick={()=>{changeGame(i.gmkey)}}>기록</li>
                                                                            <li onClick={pageRender} data-type={'video'} data-id={i.videoSq}>영상</li>
                                                                            <li onClick={pageRender} data-type={'photo'} data-id={i.photoSq}>사진</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className={`round match team01 ${i.gameStatus > 2 && i.homeScore > i.awayScore ? 'win' : ''}`}>
                                                                    <div className={`line`}>
                                                                        <span />
                                                                    </div>

                                                                    <div className={`team`}>
                                                                        <div className={`img`}>
                                                                            <img src={`/imgs/youth/emblem/em_KBL${i.homeCode == 'X1' || i.homeCode == 'X2' ? 'XX' : i.homeCode}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                            {/*<i className={`emblem-hd`} />*/}
                                                                        </div>
                                                                        <h6>{i.tnameH}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className={`round match team02 ${i.gameStatus > 2 && i.homeScore < i.awayScore ? 'win' : ''}`}>
                                                                    <div className={`line`}>
                                                                        <span />
                                                                    </div>

                                                                    <div className={`team`}>
                                                                        <div className={`img`}>
                                                                            <img src={`/imgs/youth/emblem/em_KBL${i.awayCode == 'X1' || i.awayCode == 'X2' ? 'XX' : i.awayCode}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                            {/*<i className={`emblem-ss`} />*/}
                                                                        </div>
                                                                        <h6>{i.tnameA}</h6>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        : i.ord === 2 ?
                                                            <>
                                                                <div className={`round semifinal team02 ${i.gameStatus > 2 && i.homeScore < i.awayScore ? 'win' : ''}`}>
                                                                    {/*<div className={`line`} />*/}
                                                                    {
                                                                        i.gameStatus > 2 ?
                                                                            <div className={`team`}>
                                                                                <div className={`img`}>
                                                                                    <img src={`/imgs/youth/emblem/em_KBL${i.homeScore > i.awayScore ? (i.homeCode == 'X1' || i.homeCode == 'X2' ? 'XX' : i.homeCode) : (i.awayCode == 'X1' || i.awayCode == 'X2' ? 'XX' : i.awayCode)}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                                    {/*<i className={`emblem-db`} />*/}
                                                                                </div>
                                                                                <h6>{i.homeScore > i.awayScore ? i.tnameH : i.tnameA}</h6>
                                                                            </div>
                                                                            :
                                                                            <div className={`team`}>
                                                                                <div className={`img`}>
                                                                                    <img src={`/imgs/youth/emblem/em_KBLXX.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                                </div>
                                                                                <h6>결승</h6>
                                                                            </div>
                                                                    }
                                                                    <div className={`info`}>
                                                                        <ul className={`score`}>
                                                                            {
                                                                                i.gameStatus > 0 ?
                                                                                    <>
                                                                                        <li className={`${i.gameStatus > 2 && i.homeScore > i.awayScore ? 'win' : ''}`}>{i.homeScore}</li>
                                                                                        <li className={`${i.gameStatus > 2 && i.homeScore < i.awayScore ? 'win' : ''}`}>{i.awayScore}</li>
                                                                                    </>
                                                                                    :
                                                                                        <li>{i.gameTime?.substring(0,5)}</li>
                                                                            }
                                                                        </ul>

                                                                        <ul className={`score`}> {/*240720 수정*/}
                                                                            {
                                                                                i.gameStatus > 0 ?
                                                                                    null
                                                                                    :
                                                                                    <li>{i.place}</li>
                                                                            }
                                                                        </ul>

                                                                        <ul className={`more`} style={{display: i.gameStatus > 0 ? '' : 'none'}}>
                                                                            <li onClick={()=>{changeGame(i.gmkey)}}>기록</li>
                                                                            <li onClick={pageRender} data-type={'video'} data-id={i.videoSq}>영상</li>
                                                                            <li onClick={pageRender} data-type={'photo'} data-id={i.photoSq}>사진</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className={`round match team03 ${i.gameStatus > 2 && i.homeScore > i.awayScore ? 'win' : ''}`}>
                                                                    <div className={`line`}>
                                                                        <span />
                                                                    </div>

                                                                    <div className={`team`}>
                                                                        <div className={`img`}>
                                                                            <img src={`/imgs/youth/emblem/em_KBL${i.homeCode == 'X1' || i.homeCode == 'X2' ? 'XX' : i.homeCode}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                            {/*<i className={`emblem-db`} />*/}
                                                                        </div>
                                                                        <h6>{i.tnameH}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className={`round match team04 ${i.gameStatus > 2 && i.homeScore < i.awayScore ? 'win' : ''}`}>
                                                                    <div className={`line`}>
                                                                        <span />
                                                                    </div>

                                                                    <div className={`team`}>
                                                                        <div className={`img`}>
                                                                            <img src={`/imgs/youth/emblem/em_KBL${i.awayCode == 'X1' || i.awayCode == 'X2' ? 'XX' : i.awayCode}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                            {/*<i className={`emblem-kcc`} />*/}
                                                                        </div>
                                                                        <h6>{i.tnameA}</h6>
                                                                    </div>
                                                                </div>
                                                            </>
                                                    : null
                                                )
                                            }
                                        </div>
                                    : null
                                :
                                    seasonKind == 2 ?
                                        item.list && item.list.length > 0 ?
                                            <div className={`youth-match-con-add type03`}>
                                                {
                                                    item.list.map((i, idx) =>
                                                        i.gameLevel === '2' ? //결승
                                                            <>
                                                                {
                                                                    i.gameStatus > 2 ?
                                                                        <div className={`round final`} key={`game_${i.gmkey}`}>
                                                                            <div className={`team`}>
                                                                                <div className={`img`}>
                                                                                    <img src={`/imgs/youth/emblem/em_KBL${i.homeScore > i.awayScore ? (i.homeCode == 'X1' || i.homeCode == 'X2' ? 'XX' : i.homeCode) : (i.awayCode == 'X1' || i.awayCode == 'X2' ? 'XX' : i.awayCode)}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                                </div>
                                                                                <h6>{i.homeScore > i.awayScore ? i.tnameH : i.tnameA}</h6>
                                                                            </div>
                                                                            <div className={`info`}>
                                                                                <ul className={`score`}>
                                                                                    <li className={`${i.homeScore > i.awayScore ? 'win' : ''}`}>{i.homeScore}</li>
                                                                                    <li className={`${i.homeScore < i.awayScore ? 'win' : ''}`}>{i.awayScore}</li>
                                                                                </ul>
                                                                                <button style={{display: i.gameStatus > 0 ? '' : 'none'}} onClick={()=>{changeGame(i.gmkey)}}>경기기록</button>
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                        <div className={`round final`} key={`game_${i.gmkey}`}>
                                                                            <div className={`team`}>
                                                                                <div className={`img`}>
                                                                                    <i className='emblem-kbl-wh'></i>
                                                                                </div>
                                                                                <h6>결승</h6>
                                                                            </div>
                                                                            <div className={`info`}>
                                                                                <ul className={`score`}>
                                                                                    <li><p>{i.gameTime.substring(0,5)}{i.place.includes('신관') ? ' / 신관' : i.place.includes('구관') ? ' / 구관' : ''}</p></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </>
                                                    :
                                                        i.gameLevel === '4' ? //4강, 4강 예정팀
                                                            <>
                                                                {
                                                                    i.gameStatus > 2 ?
                                                                            <div className={`round semifinal ${i.ord === 2 ? 'team01' : 'team02'} win`}>
                                                                                <div className={`line`}>
                                                                                    <span />
                                                                                </div>
                                                                                <div className={`team`}>
                                                                                    <div className={`img`}>
                                                                                        {
                                                                                            i.homeScore > i.awayScore ?
                                                                                                i.homeCode == 'X1' || i.homeCode == 'X2' ? <i className={`emblem-kbl-gr`} />
                                                                                                    : <img src={`/imgs/youth/emblem/em_KBL${i.homeCode}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                                            :
                                                                                                i.awayCode == 'X1' || i.awayCode == 'X2' ? <i className={`emblem-kbl-gr`} />
                                                                                                    : <img src={`/imgs/youth/emblem/em_KBL${i.awayCode}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                                        }
                                                                                    </div>
                                                                                    <h6>{i.homeScore > i.awayScore ? i.tnameH : i.tnameA}</h6>
                                                                                </div>
                                                                                <div className={`info`}>
                                                                                    <ul className={`score`}>
                                                                                        <li className={`${i.homeScore > i.awayScore ? 'win' : ''}`}>{i.homeScore}</li>
                                                                                        <li className={`${i.homeScore < i.awayScore ? 'win' : ''}`}>{i.awayScore}</li>
                                                                                    </ul>
                                                                                     <button style={{display: i.gameStatus > 0 ? '' : 'none'}} onClick={()=>{changeGame(i.gmkey)}}>경기기록</button>
                                                                                </div>
                                                                            </div>
                                                                    :
                                                                            <div className={`round semifinal ${i.ord === 2 ? 'team01' : 'team02'}`}>
                                                                                <div className={`line`}>
                                                                                    <span />
                                                                                </div>
                                                                                <div className={`team`}>
                                                                                    <div className={`img`}>
                                                                                        <i className={`emblem-kbl-gr`} />
                                                                                    </div>
                                                                                    <h6>4강 {i.ord === 2 ? '1' : '2'}경기</h6>
                                                                                </div>
                                                                                <div className={`info`}>
                                                                                    <ul className={`score`}>
                                                                                        <li className={``}><p>{i.gameTime.substring(0,5)}{i.place.includes('신관') ? ' / 신관' : i.place.includes('구관') ? ' / 구관' : ''}</p></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                }
                                                                <div className={`round match ${i.ord === 2 ? 'team01' : 'team06'} win`}>
                                                                    <div className={`line`}>
                                                                        <span />
                                                                    </div>
                                                                    <div className={`team`}>
                                                                        <div className={`img`}>
                                                                            {
                                                                                i.ord === 2 ?
                                                                                    i.homeCode == 'X1' || i.homeCode == 'X2' ? <i className={`emblem-kbl-gr`} />
                                                                                        : <img src={`/imgs/youth/emblem/em_KBL${i.homeCode}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                                    :
                                                                                    i.awayCode == 'X1' || i.awayCode == 'X2' ? <i className={`emblem-kbl-gr`} />
                                                                                        : <img src={`/imgs/youth/emblem/em_KBL${i.awayCode}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                            }
                                                                        </div>
                                                                        <h6>{i.ord === 2 ? i.tnameH : i.tnameA}</h6>
                                                                    </div>
                                                                </div>
                                                            </>

                                                    :
                                                        i.gameLevel === '6' ? //6강
                                                            <>
                                                                {
                                                                    i.gameStatus > 2 ?
                                                                        <div className={`round quarter ${i.ord === 1 ? 'team01' : 'team02'} win`}>
                                                                            <div className={`line`}>
                                                                                <span />
                                                                            </div>
                                                                            <div className={`team`}>
                                                                                <div className={`img`}>
                                                                                    {
                                                                                        i.homeScore > i.awayScore ?
                                                                                            i.homeCode == 'X1' || i.homeCode == 'X2' ? <i className={`emblem-kbl-gr`} />
                                                                                                : <img src={`/imgs/youth/emblem/em_KBL${i.homeCode}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                                            :
                                                                                            i.awayCode == 'X1' || i.awayCode == 'X2' ? <i className={`emblem-kbl-gr`} />
                                                                                                : <img src={`/imgs/youth/emblem/em_KBL${i.awayCode}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                                    }
                                                                                </div>
                                                                                <h6>{i.homeScore > i.awayScore ? i.tnameH : i.tnameA}</h6>
                                                                            </div>
                                                                            <div className={`info`}>
                                                                                <ul className={`score`}>
                                                                                    <li className={`${i.homeScore > i.awayScore ? 'win' : ''}`}>{i.homeScore}</li>
                                                                                    <li className={`${i.homeScore < i.awayScore ? 'win' : ''}`}>{i.awayScore}</li>
                                                                                </ul>
                                                                                <button style={{display: i.gameStatus > 0 ? '' : 'none'}} onClick={()=>{changeGame(i.gmkey)}}>경기기록</button>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={`round quarter ${i.ord === 1 ? 'team01' : 'team02'}`}>
                                                                            <div className={`line`}>
                                                                                <span />
                                                                            </div>
                                                                            <div className={`team`}>
                                                                                <div className={`img`}>
                                                                                    <i className={`emblem-kbl-gr`} />
                                                                                </div>
                                                                                <h6>6강 {i.ord === 1 ? '1' : '2'}경기</h6>
                                                                            </div>
                                                                            <div className={`info`}>
                                                                                <ul className={`score`}>
                                                                                    <li className={``}><p>{i.gameTime.substring(0,5)}{i.place.includes('신관') ? ' / 신관' : i.place.includes('구관') ? ' / 구관' : ''}</p></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                }
                                                                <div className={`round match ${i.ord === 1 ? 'team02' : 'team04'} ${i.homeScore > i.awayScore ? 'win' : ''}`}>
                                                                    <div className={`line`}>
                                                                        <span />
                                                                    </div>
                                                                    <div className={`team`}>
                                                                        <div className={`img`}>
                                                                            {
                                                                                i.homeCode == 'X1' || i.homeCode == 'X2' ? <i className={`emblem-kbl-gr`} />
                                                                                    : <img src={`/imgs/youth/emblem/em_KBL${i.homeCode}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                            }
                                                                        </div>
                                                                        <h6>{i.tnameH}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className={`round match ${i.ord === 1 ? 'team03' : 'team05'} ${i.homeScore < i.awayScore ? 'win' : ''}`}>
                                                                    <div className={`line`}>
                                                                        <span />
                                                                    </div>
                                                                    <div className={`team`}>
                                                                        <div className={`img`}>
                                                                            {
                                                                                i.awayCode == 'X1' || i.awayCode == 'X2' ? <i className={`emblem-kbl-gr`} />
                                                                                    : <img src={`/imgs/youth/emblem/em_KBL${i.awayCode}.png`} style={{width: 'auto', height: isMobile ? '6rem' : '8rem'}} />
                                                                            }
                                                                        </div>
                                                                        <h6>{i.tnameA}</h6>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        : null
                                                    )
                                                }
                                            </div>
                                        : null
                                    : null
                            }
                        </div>
                    )
                    : <EmptyGameComponent />
            }
        </>

    )
}

export default YouthTournamentComponent;